<template>
  <el-dialog
    :title="isAdd ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    append-to-body
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
    >
      <el-form-item label="是否现售窗口" prop="isCanteen">
        <el-switch
          v-model="dataForm.isCanteen"
          active-text="是"
          inactive-text="否"
        >
        </el-switch>
      </el-form-item>
      <el-form-item label="供应商" prop="orgId">
        <el-select
          v-model="dataForm.orgId"
          placeholder="请选择供应商"
          @change="changeOrgId"
        >
          <el-option
            v-for="item in orgList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="时段" prop="periodId">
        <el-select v-model="dataForm.periodId" placeholder="时段">
          <el-option
            v-for="item in periodList"
            :key="item.periodId"
            :label="item.name"
            :value="item.periodId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="窗口序号" prop="windows">
        <el-input-number
          :min="1"
          v-model="dataForm.windows"
          placeholder="窗口序号"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="窗口类目" prop="categoryIds" v-if="isView">
        <el-select
          v-model="dataForm.categoryIds"
          clearable
          multiple
          filterable
          placeholder="请选择"
          style="width: 100%"
        >
          <el-option
            v-for="category in categoryList"
            :key="category.id"
            :label="category.name"
            :value="category.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="打印机名字" prop="printerId" v-if="isView">
        <el-select
          v-model="dataForm.printerId"
          clearable
          filterable
          placeholder="请选择"
        >
          <el-option
            v-for="(item, index) in printerList"
            :key="index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="屏幕" prop="screen">
        <el-input-number
          :min="1"
          v-model="dataForm.screen"
          placeholder="屏幕"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="屏幕显示内容" prop="text" v-if="!isView">
        <el-input v-model="dataForm.text" placeholder="屏幕显示内容"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { normal } from '@/mixins';
import { keys, pick } from 'lodash';
import {
  getCategoryList,
  getOrgList,
  getOrgPeriodList,
  getPrinterList,
} from '@/utils/options';

export default {
  mixins: [normal],
  components: {},
  data() {
    return {
      visible: false,
      isAdd: false,
      index: '',
      orgList: [],
      periodList: [],
      categoryList: [],
      zoneList: [],
      printerList: [],
      isView: true,
      isSuccess: true,
      zoneOrgList: [],
      dataForm: {
        id: '',
        isCanteen: true,
        orgId: '',
        periodId: '',
        windows: 1,
        categoryIds: [],
        printerId: '',
        printerName: '',
        screen: 1,
        text: '',
        categoryNames: '',
        periodName: '',
        orgName: '',
      },
      dataRule: {
        orgId: [
          {
            required: true,
            message: '供应商不能为空',
            trigger: 'blur',
          },
        ],
        periodId: [
          {
            required: true,
            message: '时段不能为空',
            trigger: 'blur',
          },
        ],
        windows: [
          {
            required: true,
            message: '窗口序号不能为空',
            trigger: 'blur',
          },
        ],
        categoryIds: [
          {
            required: true,
            message: '窗口类目不能为空',
            trigger: 'blur',
          },
        ],
        printerId: [
          {
            required: true,
            message: '打印机名字不能为空',
            trigger: 'blur',
          },
        ],
        screen: [
          {
            required: true,
            message: '屏幕不能为空',
            trigger: 'blur',
          },
        ],
        text: [
          {
            required: true,
            message: '屏幕显示内容不能为空',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  watch: {
    'dataForm.isCanteen': {
      handler(val) {
        this.isView = val;
      },
    },
  },
  created() {},
  methods: {
    init(isAdd, index, zoneOrgList) {
      this.isAdd = isAdd;
      let row = isAdd
        ? {
            id: '',
            isCanteen: true,
            orgId: '',
            periodId: '',
            windows: 1,
            categoryIds: [],
            printerId: '',
            printerName: '',
            screen: 1,
            text: '',
            categoryNames: '',
            periodName: '',
            orgName: '',
          }
        : zoneOrgList[index];
      this.zoneOrgList = zoneOrgList;
      this.getOrgList();
      this.getPrinterList();
      this.visible = true;
      this.zoneOrgList = zoneOrgList;
      this.dataForm = pick(row, keys(this.dataForm));
      if (!isAdd) {
        this.index = index;
        this.zoneOrgList = zoneOrgList.filter((item, index) => {
          return index !== this.index;
        });
        this.dataForm.categoryIds =
          this.dataForm.categoryIds !== null
            ? row.categoryIds.split(',').map((item) => parseInt(item))
            : [];
        this.getOrgPeriodList();
        this.getCategoryList();
      }
    },
    //供应商列表
    getOrgList() {
      getOrgList(0, 1).then(({ data }) => {
        if (data && data.status === 0) {
          this.orgList = data.data.items;
        }
      });
    },
    getOrgPeriodList() {
      if (this.dataForm.orgId) {
        getOrgPeriodList(this.dataForm.orgId).then(({ data }) => {
          if (data && data.code === 0) {
            this.periodList = data.list;
          }
        });
      }
    },
    getCategoryList() {
      if (this.dataForm.orgId) {
        getCategoryList(this.dataForm.orgId).then(({ data }) => {
          if (data && data.status === 0) {
            this.categoryList = data.data.items;
          }
        });
      }
    },
    getPrinterList() {
      getPrinterList().then(({ data }) => {
        if (data && data.code === 0) {
          this.printerList = data.list;
        }
      });
    },
    changeOrgId() {
      this.dataForm.periodId = '';
      this.dataForm.categoryIds = [];
      this.getOrgPeriodList();
      this.getCategoryList();
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.checkZoneOrg();
          if (!this.isSuccess) {
            return;
          }
          if (!this.isView) {
            this.dataForm.categoryIds = '';
          } else {
            this.dataForm.categoryNames = this.categoryList
              .filter((item) => {
                return this.dataForm.categoryIds.includes(item.id);
              })
              .map((item) => {
                return item.name;
              })
              .join(',');
            this.dataForm.categoryIds = this.dataForm.categoryIds.join(',');
            this.dataForm.printerName = this.printerList.find(
              (item) => item.id === this.dataForm.printerId,
            ).name;
          }
          this.dataForm.orgName = this.orgList.find(
            (item) => item.id === this.dataForm.orgId,
          ).name;
          this.dataForm.periodName = this.periodList.find(
            (item) => item.periodId === this.dataForm.periodId,
          ).name;
          this.$message({
            message: '操作成功',
            type: 'success',
            duration: 1500,
          });
          this.$emit('submit', this.isAdd, this.index, this.dataForm);
          this.visible = false;
        }
      });
    },
    /**
     * 校验方法
     * 1.同一时段不能有多个一样的窗口序号
     */
    checkZoneOrg() {
      let zoneOrg = this.zoneOrgList.find((item) => {
        return (
          item.periodId === this.dataForm.periodId &&
          item.windows === this.dataForm.windows
        );
      });
      this.isSuccess = true;
      if (zoneOrg) {
        this.isSuccess = false;
        this.$message({
          message: '同一时段不能有多个一样的窗口序号',
          type: 'error',
          duration: 1500,
        });
      }
    },
  },
};
</script>
