<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : !disabled ? '修改' : '查看'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    append-to-body
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
    >
      <div class="tablePart">
        <el-form-item label="ID" prop="id" v-if="dataForm.id">
          <el-input
            v-model="dataForm.id"
            :disabled="true"
            placeholder="ID"
          ></el-input>
        </el-form-item>
        <el-form-item label="区域名称" prop="name">
          <el-input
            v-model="dataForm.name"
            :disabled="disabled"
            placeholder="区域名称"
          ></el-input>
        </el-form-item>
        <el-table-column label="供应商窗口">
          <el-button
            @click="editHandle(true, null, dataForm.zoneOrgList)"
            size="mini"
            type="primary"
            :disabled="disabled"
            >新增
          </el-button>
        </el-table-column>
      </div>

      <el-table
        :key="tableKey"
        :data="dataForm.zoneOrgList"
        border
        style="width: 100%"
      >
        <el-table-column
          prop="isCanteen"
          header-align="center"
          align="center"
          label="是否现场排队窗口"
        >
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.isCanteen"
              active-color="#13ce66"
              inactive-color="#ff4949"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column
          prop="orgName"
          header-align="center"
          align="center"
          label="供应商"
        >
        </el-table-column>
        <el-table-column
          prop="periodName"
          header-align="center"
          align="center"
          label="时段"
        >
        </el-table-column>
        <el-table-column
          header-align="center"
          prop="windows"
          align="center"
          label="窗口序号"
          width="160px"
        >
        </el-table-column>
        <el-table-column
          prop="categoryNames"
          header-align="center"
          align="center"
          label="窗口类目"
        >
        </el-table-column>
        <el-table-column
          prop="printerName"
          header-align="center"
          align="center"
          label="打印机名字"
        >
        </el-table-column>
        <el-table-column
          prop="screen"
          header-align="center"
          align="center"
          label="屏幕"
        >
          <template slot-scope="scope"> 屏幕{{ scope.row.screen }} </template>
        </el-table-column>
        <el-table-column
          prop="text"
          header-align="center"
          align="center"
          label="屏幕显示内容"
        >
        </el-table-column>
        <el-table-column header-align="center" align="center" label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="editHandle(false, scope.$index, dataForm.zoneOrgList)"
              :disabled="disabled"
            >
              编辑
            </el-button>
            <el-button
              type="text"
              size="small"
              @click="deleteHandle(scope.$index)"
              :disabled="disabled"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </span>
    <zone-org-edit v-if="editVisible" @submit="changeZoneOrg" ref="zoneOrgEdit">
    </zone-org-edit>
  </el-dialog>
</template>

<script>
import { normal } from '@/mixins';
import { keys, pick } from 'lodash';
import ZoneOrgEdit from './zone-org-edit';
export default {
  mixins: [normal],
  components: {
    ZoneOrgEdit,
  },
  data() {
    return {
      disabled: false,
      visible: false,
      tableKey: 1,
      editVisible: false,
      dataForm: {
        id: 0,
        name: '',
        zoneOrgList: [],
      },
      dataRule: {
        name: [
          { required: true, message: '区域名称不能为空', trigger: 'blur' },
        ],
      },
    };
  },
  created() {},
  methods: {
    init(id, disabled) {
      this.disabled = disabled;
      this.dataForm.id = id || '';
      this.dataForm.zoneOrgList = [];
      this.visible = true;
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        if (id) {
          this.$http({
            url: `/os/zone/info/${id}`,
            method: 'get',
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm = pick(data.zone, keys(this.dataForm));
            }
          });
        }
      });
    },
    addZoneOrg() {
      this.dataForm.zoneOrgList.push({
        id: '',
        orgId: '',
        periodId: '',
        isCanteen: true,
        screen: '',
        text: '',
        windows: '',
        printerId: '',
        printMachine: '',
      });
    },
    deleteHandle(index) {
      this.dataForm.zoneOrgList.splice(index, 1);
    },
    changeZoneOrg(isAdd, index, row) {
      if (!isAdd) {
        this.dataForm.zoneOrgList[index] = row;
      } else {
        this.dataForm.zoneOrgList.push(row);
      }
      this.tableKey += 1;
    },
    editHandle(isAdd, index, zoneOrgList) {
      this.editVisible = true;
      this.$nextTick(() => {
        this.$refs.zoneOrgEdit.init(isAdd, index, zoneOrgList);
      });
      // this.dataForm.zoneOrgList.map((item, index) => {
      //   item.isEdit = index === indexs;
      // });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/os/zone/${!this.dataForm.id ? 'save' : 'update'}`,
            method: 'post',
            data: this.dataForm,
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('refreshDataList');
            }
          });
        }
      });
    },
  },
};
</script>
